import { record } from './index';

const REDUX_EVENT = 'rn:redux:dispatch';
const REDUX_CATCH = 'rn:redux:catch';

const defaultOptions = {
  mapEventToAttributes: event => ({ type: event.type }),
  mapEventAndErrorToAttributes: (event, error) => ({ type: event.type, stack: error.stack, message: error.message }),
};


export const makeReduxLoggerMiddleware = (options = {}) => {

  const transforms = {
    ...defaultOptions,
    ...options,
  };

  return store => next => (action) => {
    const {
      type,
    } = action;
    try {
      const then = new Date();
      const state = next(action);
      const now = new Date();
      const duration = now - then;
      const attributes = transforms.mapEventToAttributes(action);
      if (attributes) {
        record(REDUX_EVENT, { type }, { duration });
      }
      return state;
    } catch (err) {
      const stack = err.stack;
      const message = err.message;
      const attributes = transforms.mapEventAndErrorToAttributes(action, err);
      if (attributes) {
        record(REDUX_CATCH, { type, stack, message });
      }
      throw err;
    }
  };
} 
