import React from "react";
import { SVGComponent } from "../../types";

export default ({ ...props }: SVGComponent) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M219 114c0-17-4.4-33-12-47 33-74-35-63-39-63-14 2.8-28 7.3-40 13-46-.55-86 24-99 75 24-27 41-38 51-42-2.1 1.9-4.2 3.9-6.3 5.9-16 16-29 32-40 49-8.5 15-18 28-23 45-27 98 58 57 70 50 13 6.4 27 9.9 43 9.9 42 0 78-27 91-64h-51c-24 38-81 21-83-20h138c.52-4.1.8-8.2.8-12zm-18-95c8.3 5.6 15 14 3.5 44-11-18-27-32-47-39 8.9-4.3 31-13 44-5zM24 200c-6.8-6.9-8-24 7-55 7.5 22 23 40 42 51-9.7 5.3-35 17-49 3.3zm56-100c.77-22 20-40 44-40s43 18 44 40H81z"
        fill="#1ebbee"
      />
    </svg>
  );
}