import uuid from 'uuid';
import invariant from 'invariant';
import interceptXhr from './xhook';
import awsExports from './aws-exports';
import { init as initApm } from '@elastic/apm-rum'

const SESSION_START = 'rn:session:start';
const SESSION_STOP = 'rn:session:stop';
const SESSION_TIMEOUT = 5000;

let session = null;
let timeoutId = null;
let deviceInfo = null;
let getUsername = null;
let getOtherAttributes = null;
let Analytics = null;
let lastUsername = null;
let apm = null;

const makeSession = () => ({
  session_id: uuid.v4(),
  start_timestamp: new Date(),
});


const handleAppStateChange = (nextAppState) => {
  const fn = map[nextAppState];
  if (fn) fn(nextAppState);
  console.log(nextAppState);
};

const handleWindowError = (event) => {
  const {
    message,
    filename,
    lineno,
    colno,
    error,
  } = event;

  const stack = event.error && event.error.stack;
  const string_values = {
    message,
    filename,
    stack,
  };

  const numeric_values = {
    lineno,
    colno,
  }

  record('error', string_values, numeric_values);
}

export const startLogging = async (options) => {
  Analytics = options.Analytics;
  getUsername = options.getUsername;
  getOtherAttributes = options.getOtherAttributes;
  
  invariant(Analytics, 'Analytics must be the AWS Analytics Object');
  invariant(getUsername, 'getUsername is required');
  invariant(getOtherAttributes, 'getOtherAttributes is required');

  session = makeSession();
  interceptXhr();

  window.addEventListener('error', handleWindowError);

  const {
    appName,
    appVersion,
  } = getOtherAttributes();

  apm = initApm({
    serviceName: appName,
    serverUrl: 'https://logapi.eogresources.com/apm/0/',
    serviceVersion: appVersion,
    distributedTracingOrigins: [location.origin],
    ...(options.apm || {})
  });

};

export const resetSession = () => {
  session = makeSession();
}

/**
 * Records a free form log message, optionally with attributes and metrics.
 * @param  {string} message - A free form message.
 * @param  {object} [attributes] - A map of names and term (strings, numeric keys, etc) values.
 * @param  {object} [metrics] - A map of names and numeric (real numbers, metrics, etc) values.
 */
export const recordMessage = async (message, attributes, metrics) => {
  invariant(message, 'message cannot be null or undefined');
  const normAttributes = attributes || {};
  const normMetrics = metrics || {};

  const type = 'log';
  const nextAttributes = {
    ...normAttributes,
    message,
  };

  return record(type, nextAttributes, normMetrics);
}

/**
 * Records a free form log message, optionally with attributes and metrics.
 * @param  {string} message - A free form message.
 * @param  {object} [attributes] - A map of names and term (strings, numeric keys, etc) values.
 * @param  {object} [metrics] - A map of names and numeric (real numbers, metrics, etc) values.
 */
export const recordScreenVisit = async (screenName, attributes, metrics) => {
  invariant(screenName, 'screenName cannot be null or undefined');
  const normAttributes = attributes || {};
  const normMetrics = metrics || {};

  const type = 'visit';
  const nextAttributes = {
    ...normAttributes,
    screenName,
  };

  return record(type, nextAttributes, normMetrics);
}

export const recordNetworkRequest = async (requestUrl, duration, attributes, metrics) => {
  invariant(requestUrl, 'requestUrl cannot be null or undefined');
  invariant(duration && Number(duration), 'duration must be a number');
  const normAttributes = attributes || {};
  const normMetrics = metrics || {};

  const type = 'network_request';
  const nextAttributes = {
    ...normAttributes,
    requestURL: requestUrl,
    duration,
  };

  return record(type, nextAttributes, normMetrics);
}

export const recordError = async (message, err, attributes, metrics) => {
  invariant(message, 'message cannot be null or undefined');

  const error_stack = err && err.stack && err.stack.toString && err.stack.toString();
  const error_message = err & err.message && err.stack.toString && err.stack.toString();
  record('log_error', { error_stack, error_message, message });
};


// eslint-disable-next-line
/**
 * records an event. This method is intended to be used by helper functions and should be used as a last resort.
 *  Use the 'log' function for messages.
 *  Use the middleware for action logging, errors and networking metrics.
 * @param  {string} type - Type of the event. This should be a low cardinality category, not a value.
 * @param  {object} attributes - A map of names and term (strings, numeric keys, etc) values.
 * @param  {object} metrics - A map of names and numeric (real numbers, metrics, etc) values.
 */
export const record = async (type, attributes, metrics) => {
  invariant(session, 'a session is required before recording events. Did you forget to call start()?');

  const {
    start_timestamp,
    stop_timestamp,
    ...others
  } = session;

  const username = getUsername();
  if (lastUsername != username) {
    apm.setUserContext({
      username,
    })
  }
  const otherAttributes = getOtherAttributes();

  invariant(otherAttributes, 'getOtherAttributes must return an object');
  invariant(otherAttributes.appName, 'getOtherAttributes must include appName');
  invariant(otherAttributes.appVersion, 'getOtherAttributes must include appVersion');
  
  const normAttributes = {
    platform: 'browser',
    ...otherAttributes,
    username,
    start_timestamp: start_timestamp.toISOString(),
    stop_timestamp: stop_timestamp ? stop_timestamp.toISOString() : undefined,
    ...others,
    ...attributes,
  };

  const normAttributes2 = {}
  const normMetrics2 = {}

  // console.log(type, normAttributes, metrics)
  const response = await Analytics.record(type, normAttributes, metrics);
  // console.log(response);
};


/**
 * Logs an error object
 * @param  {} err - An Error object.
 */
export const error = async (err) => {
  const stack = err.stack;
  const message = err.message;
  record('log_error', { stack, message });
};
