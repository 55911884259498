// import xhook from 'xhook';
import { record } from './index'
const NETWORK_REQUEST = 'rn:network_request';

const handleBefore = (request) => {
  request.xStart = new Date();
};

const handleAfter = (request, response) => {
  if (request.url.includes('eogresources')) {
    const now = new Date();
    const duration = now - request.xStart;
    const {
      status,
      statusText,
      headers,
    } = response;
    const {
      timeout,
      method,
      url,
    } = request;

    const attributes = {
      statusText,
      ...headers,
      method,
      url,
    };

    const metrics = {
      duration,
      timeout,
      status,
    };

    if (response.data && response.data.responses) {
      response.data.responses.forEach((response, i) => {
        metrics[`es-took-${i}`] = response.took;
      });
    }

    record(NETWORK_REQUEST, attributes, metrics);
  }
};


// (function(open) {
//   XMLHttpRequest.prototype.open = function(XMLHttpRequest) {
//      var self = this;
//      this.addEventListener("readystatechange", function() {
//          if (this.readyState == 4) {
//             console.log('done', this);
//             handleAfter(this);
//          }
//          if (this.readyState === 1) {
//           console.log('opened', this);
//           handleOpen(this);
//          }
//      }, false);
//      open.apply(this, arguments);
//  };
//  })(XMLHttpRequest.prototype.open);

/* XHOOK BREAKS THE CONTRACT OF COMMON WEB NETWORKING STACKS. */
/* TODO: REPLACE */
export default () => {
  // xhook.before(handleBefore);
  // xhook.after(handleAfter);
};
