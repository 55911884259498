// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'us-east-1:b8a41983-e50c-4035-a0db-35f89fc7bfc2',
    'aws_cognito_region': 'us-east-1',
    'aws_mobile_analytics_app_id': '7d9e85d3ba7d4fccaa83fa0244d8d816',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': 'c5835b47-9cb1-4576-857d-68f45f98e373',
    'aws_project_name': 'com.eogresources.common',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'comeogresourcescommo-mobilehub-1512860956',
}

export default awsmobile;
